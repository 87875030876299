<template>
	<div class="inner">
		<div class="container">
			<div class="title-btn blf ft24 tc">{{name || funcVideo.infoName}}</div>
			<div class="pt50">
				<div class="introinfo-nav tc ft24 el-border-bottom pb30 mb30">
					<el-row>
						<el-col :span="12">
							<div class="el-border-right hover tr3" @click="handleMoreVideo(code)">
								车型视频
							</div>
						</el-col>
						<el-col :span="12">
							<div class="hover red tr3" @click="handleFunVideo(code)">功能视频</div>
						</el-col>
					</el-row>
				</div>
			</div>
			<div v-if="funcVideo.classifyVOList && funcVideo.classifyVOList.length == 0" style="min-height: 79vh;">
				<el-empty :image-size="200" description="暂无任何功能视频信息"></el-empty>
			</div>
			<div class="mb20 pt30 pb20" v-for="(item,index) in funcVideo.classifyVOList" :key="index" v-else>
				<div class="ft24 pb40">{{item.name}}</div>
				<el-row :gutter="65">
					<el-col :span="12" v-for="(subItem, index) in item.videoUrl" :key="index">
						<div class="video-list over hover tr3">
							<div class="video-list-img2 video-list-img over">
								<video :src="subItem.url" width="100%" height="100%" controls="controls" @play="handlePlayVideo(subItem.videoIndex)"></video>
							</div>
							<div class="l16 ft20 mt20 line2 mb30 tc">{{subItem.name}}</div>
						</div>
					</el-col>
				</el-row>
			</div>
			<!--<div class="tc pb50 mb50">-->
				<!--<el-pagination background layout="prev, pager, next" :total="100">-->
				<!--</el-pagination>-->
			<!--</div>-->
		</div>
	</div>
</template>

<script>
	import { getFuncVideo, getByInfoCode} from '@/api/index.js'
	export default {
		data() {
			return {
				code: '',
				name: '',
				videoElement:[],
				funcVideo:{
					id: '',
					infoCode: '',
					infoName: '',
					classifyVOList: []
				}
			}
		},
		created(){
			this.code = this.$route.query.code
			this.getMainVideo(this.$route.query.code)
			this.getFunVideoInfo(this.$route.query.code)
		},
		mounted(){
			this.videoElement = document.getElementsByTagName('video')
		},
		methods: {
			getMainVideo(code){
				getByInfoCode(code).then(res=>{
					if(res.code === 200){
						this.name = res.data.name;
					}
				})
			},


			getFunVideoInfo(code){
				getFuncVideo(code).then(res=>{
					if(res.code === 200){
						let temClassifyArr = []
						if(res.data.classifyVOList && res.data.classifyVOList.length > 0){
							let videoIndex = 0
							res.data.classifyVOList.forEach(item=>{
								let temVideoUrlArr = []
								if(item.videoUrl){
									let temVideoUrl = item.videoUrl.split(',')
									if(temVideoUrl && temVideoUrl.length > 0){
										temVideoUrl.forEach(subItem=>{
											let subItemVideo = subItem.substr(subItem.indexOf('/') + 1)
											temVideoUrlArr.push({
												name: subItemVideo.substring(0,subItemVideo.indexOf(".")),
												url: subItem,
												code: item.code,
												videoIndex: videoIndex
											})
											videoIndex = videoIndex + 1
										})
									}
								}
								temClassifyArr.push({
									code: item.code,
									name: item.name,
									videoUrl: temVideoUrlArr
								})
							})
						}

						this.funcVideo = {
							id: res.data.id,
							infoCode: res.data.infoCode,
							infoName: res.data.infoName,
							classifyVOList: temClassifyArr
						}
					}
				})
			},

			/** 跳转到更多视频 ***/
			handleMoreVideo(code){
				this.$router.push({
					path: '/carType-video-list',
					query:{
						code: code
					}
				})
			},
			/** 功能视频 **/
			handleFunVideo(code){
				this.$router.push({
					path: '/carType-GNvideo-list',
					query:{
						code: code
					}
				})
			},

			/**
			 * 视频播放
			 * @param index
			 */
			handlePlayVideo(index){
				const videoElement = this.videoElement
				if (videoElement && videoElement.length > 0) {
					for (let i = 0; i < videoElement.length; i++) {
						if (i === index) {
							this.videoElement[i].play()
						} else {
							this.videoElement[i].pause()
						}
					}
				}
			},
		}
	}
</script>

<style scoped="scoped">
	.inner{padding-top: 90px;}
	.title-btn {
		height: 70px;
		background-color: #b4232f;
		border-radius: 50px;
		line-height: 70px;
		background-image: url(../assets/btn-bg.png);
		background-position: center;
		background-size: cover;
		margin-bottom: 50px;
	}

	.details-li img {
		display: inline-block;
		line-height: 1.6;
		vertical-align: baseline;
	}

	.video-list-img {
		width: 100%;
		background-color: #ddd;
		background-position: center;
		background-size: cover;
		border-radius: 10px;
	}
	.video-list-img2{
		width: 100%;
		background-color: #ddd;
		background-position: center;
		background-size: cover;
		border-radius: 10px;
		height:374px ;
	}
</style>

